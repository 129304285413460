var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{ name: 'invoiceConcepts', params: { setting: _vm.setting } }}},[_vm._v(" Concepts ")]),_c('v-tab',{attrs:{"to":{
                name: 'invoiceCategories',
                params: {
                    setting: _vm.setting,
                },
            }}},[_vm._v(" Categories ")]),(false)?_c('v-tab',{attrs:{"to":{
                name: 'invoiceTypes',
                params: {
                    setting: _vm.setting,
                },
            }}},[_vm._v(" Types ")]):_vm._e()],1),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-card-title',{ref:"title"},[_c('span',[_vm._v("Invoicing")])]),_c('v-card-subtitle',{ref:"subtitle",staticClass:"my-0"},[_c('v-divider')],1),_c('v-card-text',{staticClass:"overflow-y-auto",style:(`height: ${_vm.height}px`)},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }